import {createTheme} from "@mui/material/styles";
import {PaletteColorOptions} from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    admin: string;
    tertiary: string;
    darkBlueAndDarker: string;
    grayAndDarker: string;
  }

  interface PaletteOptions {
    admin: PaletteColorOptions | undefined;
    tertiary: PaletteColorOptions | undefined;
    darkBlueAndDarker: PaletteColorOptions | undefined;
    grayAndDarker: PaletteColorOptions | undefined;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    admin: true;
  }

  interface ButtonPropsColorOverrides {
    admin: true;
    tertiary: true;
    darkBlueAndDarker: true;
    grayAndDarker: true;
  }
}

const globalThemePalette = {
  palette: {
    primary: {
      main: "#5D51B8",
      dark: "#493EA1",
    },
    secondary: {
      main: "#1B1B1E"
    },
    tertiary: {
      main: "#FFFFFF",
      dark: "#F5F5F5",
      contrastText: '#FFFFFF',
    },
    darkBlueAndDarker: {
      main: "#26284B",
      dark: "#26284B80",
      contrastText: '#FFFFFF',
    },
    grayAndDarker: {
      main: "#49494C",
      dark: "#49494C80",
      contrastText: '#FFFFFF',
    },
    admin: {
      main: "#58585B",
      dark: "#5D51B8",
      contrastText: '#FFFFFF',
    },
    white: {
      100: '#FFFFFF',
      90: '#FFFFFFE6',
      80: '#FFFFFFCC',
      70: '#FFFFFFB3',
      50: '#FFFFFF80'
    }
  },
}

export const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: {variant: "admin"},
          style: {
            padding: "6px 8px",
            border: "1px solid black",
            borderRadius: 0,
            width: "289px",
            height: "63px",
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "capitalize",
            color: globalThemePalette.palette.admin.contrastText,
            backgroundColor: globalThemePalette.palette.admin.main,

            "&:hover": {
              backgroundColor: globalThemePalette.palette.admin.dark,
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          // correct values by default:
          // fontWeight: 500,
          // borderRadius: "4px",
          //______________________________________________
          padding: "10px 15px",
          width: "100%",
          minWidth: 0,
          height: "auto",
          fontFamily: 'General Sans, sans-serif',
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "150%",
          letterSpacing: "0.15px",
          textTransform: "none"
        },
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: globalThemePalette.palette.white["100"]
        }
      }
    }
  },
  typography: {
    fontFamily: 'General Sans, sans-serif',
    h1: {
      fontSize: '34px',
    },
    h2: {
      fontSize: '28px',
    }
  },
  ...globalThemePalette
});
