import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import 'highcharts/modules/stock';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { DISABLE_CHART_EXPORTING_MENU } from '@/constants/chartOptions';
import { adjustRiskZoneChartLabelForAlertStatusTrending } from '@/helpers/riskZoneMethods';
import { adjustSeverityLabelForAlertStatusTrending } from '@/helpers/severityLevelMethods';
import { ChartData } from '@/types/chartPoint';

const Figure = styled.figure`
  width: 100%;
  margin: 1em auto;
`;

interface TooltipFormatterContextObject {
  x?: number;
  y: number;
  series: { name: string };
  color: string;
  points: Highcharts.Point[];
}

interface IAlertStatusTrendingChartProps {
  riskZoneData: ChartData;
  totalFlowSeverityData: ChartData;
  bacunsSeverityData: ChartData;
}

export const AlertStatusTrendingChart = ({
  riskZoneData,
  totalFlowSeverityData,
  bacunsSeverityData,
}: IAlertStatusTrendingChartProps) => {
  const backgroundColor = '#101014';
  const chartBackground = '#282829';

  riskZoneData.sort((a, b) => a.x - b.x);

  const chartOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: backgroundColor,
      height: 343,
      type: 'line',
      zoomType: 'x',
    },
    title: {
      text: undefined,
    },
    rangeSelector: {
      enabled: true,
      selected: 0, // 24h by default
      buttons: [
        {
          type: 'day',
          count: 1,
          text: '24H',
          title: '24H Alert Status',
        },
        {
          type: 'day',
          count: 7,
          text: '7D',
          title: '7D Alert Status',
        },
      ],
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      borderWidth: 0,
      itemStyle: {
        color: '#FFFFFF80',
      },
      itemHoverStyle: {
        color: '#FFFFFF',
      },
    },
    xAxis: {
      maxPadding: 0,
      gridLineWidth: 1,
      gridLineColor: chartBackground,
      type: 'datetime',
      tickLength: 0,
      showLastLabel: true,
      labels: {
        format: '{value:%e %b}',
        style: {
          color: '#F7F7F7',
          fontSize: '14px',
        },
      },
      tickInterval: 24 * 3600 * 1000,
      tickPositioner: function (this: Highcharts.Axis) {
        const oneDay = 24 * 3600 * 1000;
        const positions: number[] = [];
        const axis = this as any;

        let min = axis.min ?? axis.dataMin ?? Date.now() - oneDay;
        let max = axis.max ?? axis.dataMax ?? Date.now();

        // Transformation to full days  **LOCAL TIME**
        min = new Date(min).setHours(0, 0, 0, 0);
        max = new Date(max).setHours(0, 0, 0, 0);

        for (let time = min; time <= max; time += oneDay) {
          positions.push(time);
        }

        return positions;
      },
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      gridLineColor: chartBackground,
      title: {
        text: undefined,
      },
      min: 0,
      max: 5,
      tickInterval: 1,
      tickAmount: 6,
      labels: {
        formatter: function (this: { value: number }) {
          return adjustRiskZoneChartLabelForAlertStatusTrending(this.value).toUpperCase();
        },
        style: {
          color: '#F7F7F7',
          lineHeight: '15.809px',
        },
      },
    },

    tooltip: {
      shared: true,
      crosshairs: true,
      backgroundColor: '#1B1B1E',
      borderRadius: 8,
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      formatter: function (this: TooltipFormatterContextObject) {
        const header = this.x ? format(this.x, 'eee, yyyy MMM dd hh:mm a') : '';

        const body = this.points
          ?.map((point) => {
            const customText =
              point.series.name === 'Alert Status'
                ? adjustRiskZoneChartLabelForAlertStatusTrending(point.y).toUpperCase()
                : adjustSeverityLabelForAlertStatusTrending(point.y).toUpperCase();

            return `<span style="color:${point.color}">●</span> <b>${point.series.name}</b>: ${customText}`;
          })
          .join('<br/>');

        return `<b>${header}</b><br/>${body}`;
      },
    },
    plotOptions: {
      line: {
        marker: {
          enabled: true,
          radius: 1,
        },
        states: {
          hover: {
            lineWidth: 2,
          },
        },
      },
    },
    series: [
      {
        type: 'line',
        name: 'Alert Status',
        data: riskZoneData,
        lineWidth: 2,
        marker: {
          symbol: 'square',
        },
      },
      {
        type: 'line',
        name: 'On-Chain Severity',
        data: totalFlowSeverityData,
        visible: false,
        lineWidth: 2,
        marker: {
          symbol: 'circle',
        },
      },
      {
        type: 'line',
        name: 'Adjusted Balance Severity',
        data: bacunsSeverityData,
        visible: false,
        lineWidth: 2,
        marker: {
          symbol: 'diamond',
        },
      },
    ],
    time: {
      useUTC: false,
    },
    ...DISABLE_CHART_EXPORTING_MENU,
  };

  return (
    <Figure>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Figure>
  );
};
