import { mapChainData } from '@/helpers/mapChartsData';
import { useFlowChartsManager } from '@/hooks/useFlowChartsManager';
import AnomalyTimeLineChart from '@/components/molecules/Charts/AnomalyTimeLineChart';
import ColumnChart from '@/components/molecules/Charts/ColumnChart';
import Select from '@/components/molecules/Select/Select';
import { OutflowTotalflowRatioTimeLineChart } from '@/views/EntityMonitoringView/components/OutflowTotalflowRatioTimeLineChart/OutflowTotalflowRatioTimeLineChart';
import {
  StyledContainer,
  StyledTitle,
  StyledChartWrapper,
  StyledScrollWrapper,
} from '@/views/EntityMonitoringView/components/ChainDataCharts/ChainDataCharts.styled';
import { IGetMonitoringDetailsResponse } from '@/api/monitoring/monitoring.types';

export const ChainDataCharts = ({
  agioId,
  balances,
  netflowData,
  totalFlowData,
  hourlyNetflowData,
  hourlyTotalFlowData,
  hourlyOutflowTotalflowRatio,
}: Pick<
  IGetMonitoringDetailsResponse,
  | 'agioId'
  | 'balances'
  | 'netflowData'
  | 'totalFlowData'
  | 'hourlyNetflowData'
  | 'hourlyTotalFlowData'
  | 'hourlyOutflowTotalflowRatio'
>) => {
  const chartData = mapChainData(balances);
  const formattedNetFlowData = mapChainData(netflowData);
  const formattedTotalFlowData = mapChainData(totalFlowData);

  const formattedHourlyNetflowData = mapChainData(hourlyNetflowData);
  const formattedHourlyTotalflowData = mapChainData(hourlyTotalFlowData);

  const formattedHourlyOutflowTotalflowRatioData = mapChainData(hourlyOutflowTotalflowRatio);

  const {
    shouldRenderSelect,
    filteredFlowDataChartOptions,
    handleSelectedFlowDataChange,
    selectedFlowDataChart,
    shouldRenderFlowsChart,
    generatedFlowData,
    generatedTitle,
    isHourlyChart,
  } = useFlowChartsManager({
    netflowData: formattedNetFlowData,
    totalflowData: formattedTotalFlowData,
    hourlyNetflowData: formattedHourlyNetflowData,
    hourlyTotalflowData: formattedHourlyTotalflowData,
  });

  if (!chartData.length && !shouldRenderFlowsChart && !formattedHourlyOutflowTotalflowRatioData.length) return null;

  return (
    <StyledContainer>
      <StyledTitle>Timeseries</StyledTitle>
      <StyledScrollWrapper>
        <StyledChartWrapper>
          {!!chartData.length && <AnomalyTimeLineChart alertId={agioId} chartData={chartData} />}

          {shouldRenderFlowsChart && (
            <>
              {shouldRenderSelect && (
                <Select
                  options={filteredFlowDataChartOptions}
                  onChange={handleSelectedFlowDataChange}
                  value={selectedFlowDataChart || ''}
                  clearVisible={false}
                />
              )}

              <ColumnChart
                key={selectedFlowDataChart}
                flowData={generatedFlowData}
                isTotalFlow={selectedFlowDataChart === 'totalflow' || selectedFlowDataChart === 'totalflow-hourly'}
                title={generatedTitle}
                isHourlyChart={isHourlyChart}
              />
            </>
          )}

          {/*{!!formattedHourlyOutflowTotalflowRatioData.length && (*/}
          {/*  <OutflowTotalflowRatioTimeLineChart chartData={formattedHourlyOutflowTotalflowRatioData} />*/}
          {/*)}*/}
        </StyledChartWrapper>
      </StyledScrollWrapper>
    </StyledContainer>
  );
};
