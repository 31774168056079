import { mapAlertStatusTrendingChartData, mapSeverityChartDataFromRiskZoneDataset } from '@/helpers/mapChartsData';
import { AlertStatusTrendingChart } from '@/components/molecules/Charts/AlertStatusTrendingChart';
import {
  StyledChartsWrapper,
  StyledChartWrapper,
  StyledScrollWrapper,
  StyledSpan,
} from '@/views/EntityMonitoringView/components/MonitoredStatusChart/MonitoredStatusChart.styled';
import { IRiskZoneDTO } from '@/types/riskZone';

interface IMonitoredStatusChartProps {
  riskZones: IRiskZoneDTO[];
}

export const MonitoredStatusChart = ({ riskZones }: IMonitoredStatusChartProps) => {
  const monitoredStatusDataset = mapAlertStatusTrendingChartData(riskZones);
  const totalFlowSeverityDataset = mapSeverityChartDataFromRiskZoneDataset(riskZones, 'totalFlow');
  const bacunsSeverityDataset = mapSeverityChartDataFromRiskZoneDataset(riskZones, 'netflow');

  if (!riskZones.length) return null;

  return (
    <StyledChartsWrapper>
      <StyledSpan>Alert Status</StyledSpan>
      <StyledScrollWrapper>
        <StyledChartWrapper>
          <AlertStatusTrendingChart
            riskZoneData={monitoredStatusDataset}
            totalFlowSeverityData={totalFlowSeverityDataset}
            bacunsSeverityData={bacunsSeverityDataset}
          />
        </StyledChartWrapper>
      </StyledScrollWrapper>
    </StyledChartsWrapper>
  );
};
