import { Typography } from '@mui/material';
import { FONT_SIZE } from '@/views/EntityMonitoringView/components/EntityDetails/components/contstants';
import { formatLargeDollarValueWithSuffix } from '@/helpers/helpers';
import { RiskInfoRow } from '@/views/EntityMonitoringView/components/EntityDetails/components/ShortTermRisk/RiskInfoRow';
import {
  StyledColumnGridWrapper,
  StyledRowGridWrapper,
} from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';

export const SignificantData = ({
  balance,
  outflow,
  inflow,
  totalFlow,
  netflow,
}: Pick<IEntityDetailsProps, 'balance' | 'outflow' | 'inflow' | 'totalFlow' | 'netflow'>) => {
  return (
    <>
      <Typography color="white.100" fontSize={FONT_SIZE}>
        On-Chain Data
      </Typography>

      <StyledColumnGridWrapper>
        <RiskInfoRow
          tooltip={
            <>
              <p>
                Observable Balance represents the on-chain balances tracked using our continuously evolving wallet
                monitoring methodology.
              </p>
              <p>
                While we strive to capture the most comprehensive view possible, we cannot guarantee 100% wallet
                coverage.
              </p>
            </>
          }
          title="Observable Balance"
        >
          <Typography color="white.100" fontSize={FONT_SIZE} align="right">
            {balance ? formatLargeDollarValueWithSuffix(balance) : ''}
          </Typography>
        </RiskInfoRow>

        <StyledRowGridWrapper>
          <Typography color="#ffffff80" fontSize={FONT_SIZE}>
            24H Inflows
          </Typography>
          <Typography color="white.100" fontSize={FONT_SIZE} align="right">
            {inflow ? formatLargeDollarValueWithSuffix(inflow) : ''}
          </Typography>
        </StyledRowGridWrapper>

        <StyledRowGridWrapper>
          <Typography color="#ffffff80" fontSize={FONT_SIZE}>
            24H Outflows
          </Typography>
          <Typography color="white.100" fontSize={FONT_SIZE} align="right">
            {outflow ? formatLargeDollarValueWithSuffix(outflow) : ''}
          </Typography>
        </StyledRowGridWrapper>

        <StyledRowGridWrapper>
          <Typography color="#ffffff80" fontSize={FONT_SIZE}>
            24H Totalflows
          </Typography>
          <Typography color="white.100" fontSize={FONT_SIZE} align="right">
            {totalFlow ? formatLargeDollarValueWithSuffix(totalFlow) : ''}
          </Typography>
        </StyledRowGridWrapper>

        <StyledRowGridWrapper>
          <Typography color="#ffffff80" fontSize={FONT_SIZE}>
            24H Netflows
          </Typography>
          <Typography color={netflow && netflow > 0 ? 'green' : 'red'} fontSize={FONT_SIZE} align="right">
            {netflow ? formatLargeDollarValueWithSuffix(netflow) : ''}
          </Typography>
        </StyledRowGridWrapper>
      </StyledColumnGridWrapper>
    </>
  );
};
