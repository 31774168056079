import {
  StyledSeverityIcon,
  StyledSeverityWrapper,
} from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon.styled';
import { adjustSeverityColorFromValue, adjustSeverityLabel } from '@/helpers/severityLevelMethods';
import { TSeverity } from '@/types/severityLevel';

interface IAlertExclamationIconProps {
  severity: TSeverity;
  fontSize?: string;
}

export const AlertSeverityBarsIcon = ({ severity, fontSize }: IAlertExclamationIconProps) => {
  const severityColor = adjustSeverityColorFromValue(severity);
  const isNoneSeverity = severity === 'none';
  const showIcon = !isNoneSeverity && severity !== null;

  return (
    <StyledSeverityWrapper color={severityColor} fontSize={fontSize}>
      {severity ? adjustSeverityLabel(severity) : 'Data unavailable'}
      {showIcon && <StyledSeverityIcon color={severityColor} className={severity} />}
    </StyledSeverityWrapper>
  );
};
