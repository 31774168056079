import { useMemo, useState } from 'react';
import { TableBody, TableHead } from '@mui/material';
import { INITIAL_PAGE_SIZE } from '@/constants/pagination';
import { AnomalyTableRow } from '@/views/EntityMonitoringView/components/AnomaliesTable/components/AnomalyTableRow/AnomalyTableRow';
import {
  StyledWrapper,
  StyledPagination,
  StyledNoRows,
  StyledHeaderCell,
  StyledTable,
  StyledHeaderTableRow,
  StyledTableWrapper,
  StyledSectionTitle,
} from '@/views/EntityMonitoringView/components/AnomaliesTable/AnomaliesTable.styled';
import { IAnomalyShortForMonitoringDto } from '@/types/anomaly';

interface IAnomaliesTableProps {
  latestAnomalies: IAnomalyShortForMonitoringDto[];
}

export const AnomaliesTable = ({ latestAnomalies }: IAnomaliesTableProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const filteredAnomalies = useMemo(() => {
    return latestAnomalies.filter((anomaly) => anomaly.severity === 'high' || anomaly.severity === 'extreme');
  }, [latestAnomalies]);

  const totalNumberOfFilteredItems = useMemo(() => {
    return filteredAnomalies.length;
  }, [filteredAnomalies]);

  const paginatedAnomaliesList = useMemo(() => {
    const paginatedBuckets: IAnomalyShortForMonitoringDto[][] = [];

    filteredAnomalies.forEach((anomaly, index) => {
      if (index % 5 === 0) {
        paginatedBuckets.push([]);
      }

      paginatedBuckets[paginatedBuckets.length - 1].push(anomaly);
    });

    return paginatedBuckets;
  }, [filteredAnomalies]);

  if (!totalNumberOfFilteredItems) {
    return (
      <StyledWrapper>
        <StyledSectionTitle>Anomalies</StyledSectionTitle>
        <StyledNoRows>No anomalies with extreme or high severities were found in the last 90 days.</StyledNoRows>
      </StyledWrapper>
    );
  }

  return (
    <>
      <StyledWrapper>
        <StyledSectionTitle>Anomalies</StyledSectionTitle>

        <StyledTableWrapper>
          <StyledTable aria-label="anomalies table">
            <TableHead>
              <StyledHeaderTableRow>
                <StyledHeaderCell />
                <StyledHeaderCell>Severity</StyledHeaderCell>
                <StyledHeaderCell>Timestamp</StyledHeaderCell>
                <StyledHeaderCell>Variable</StyledHeaderCell>
                <StyledHeaderCell>Anomaly details</StyledHeaderCell>
              </StyledHeaderTableRow>
            </TableHead>
            <TableBody>
              {paginatedAnomaliesList[currentPage - 1].map((row) => (
                <AnomalyTableRow key={row.id} row={row} />
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableWrapper>
        <StyledPagination
          totalNumberOfItems={totalNumberOfFilteredItems}
          pageSize={INITIAL_PAGE_SIZE}
          currentPage={currentPage}
          setPage={setCurrentPage}
        />
      </StyledWrapper>
    </>
  );
};
