import { Typography } from '@mui/material';
import { FONT_SIZE } from '@/views/EntityMonitoringView/components/EntityDetails/components/contstants';
import { AlertSeverityBarsIcon } from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon';
import { RiskInfoRow } from '@/views/EntityMonitoringView/components/EntityDetails/components/ShortTermRisk/RiskInfoRow';
import { RiskZoneBarsIcon } from '@/components/RiskZoneBarsIcon/RiskZoneBarsIcon';
import { StyledColumnGridWrapper } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';

export const ShortTermRisk = ({
  riskZone,
  tag,
  onChainActivity,
  adjustedBalance,
  flowDynamics,
}: Pick<IEntityDetailsProps, 'riskZone' | 'tag' | 'onChainActivity' | 'adjustedBalance' | 'flowDynamics'>) => {
  return (
    <>
      <Typography color="white.100" fontSize={FONT_SIZE}>
        Activity Overview
      </Typography>

      <StyledColumnGridWrapper>
        <RiskInfoRow
          tooltip={
            <p>
              Represents a combined status derived from the severity levels of On-Chain Activity and Adjusted Balance.
              This metric determines when alerts are issued to end users, providing a high-level overview of current
              activity.
            </p>
          }
          title="Alert Status"
        >
          <RiskZoneBarsIcon riskZone={riskZone} fontSize={FONT_SIZE} />
        </RiskInfoRow>

        <RiskInfoRow
          tooltip={
            <p>
              Tags contextualize anomalies by pointing to potential causes, such as balance anomalies or irregular flow
              activity.
            </p>
          }
          title="Tag"
        >
          <Typography color="#ffffff80" fontSize={FONT_SIZE}>
            {tag}
          </Typography>
        </RiskInfoRow>

        <RiskInfoRow
          tooltip={
            <p>
              Tracks the totalflows of observable on-chain transactions, identifying unusual surges or drops that
              deviate from general historical patterns.
            </p>
          }
          title="On-Chain Activity Severity"
        >
          <AlertSeverityBarsIcon severity={onChainActivity} fontSize={FONT_SIZE} />
        </RiskInfoRow>

        <RiskInfoRow
          tooltip={
            <p>
              Analyzes net balance trends to detects anomalous outflows relative to the cumulative netflows and the
              overall size of the entity’s observable balances.
            </p>
          }
          title="Adjusted Balance Severity"
        >
          <AlertSeverityBarsIcon severity={adjustedBalance} fontSize={FONT_SIZE} />
        </RiskInfoRow>

        <RiskInfoRow
          tooltip={
            <p>
              Flow Dynamics highlights the dominant driver of an entity&#39;s observable on-chain activity by measuring
              outflow:totalflow ratio.
              <ul>
                <li>
                  If <strong>ratio ≤ 1/3,</strong> inflows drive activity
                </li>
                <li>
                  If <strong>ratio ≥ 2/3,</strong> outflows drive activity
                </li>
                <li> Otherwise, both inflows and outflows are balanced </li>
              </ul>
            </p>
          }
          title="Flow Dynamics"
        >
          <Typography color="#ffffff80" fontSize={FONT_SIZE}>
            {flowDynamics}
          </Typography>
        </RiskInfoRow>
      </StyledColumnGridWrapper>
    </>
  );
};
