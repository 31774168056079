import styled from '@emotion/styled';
import SeverityIcon from '@/assets/icons/severityBars.svg?react';

export const StyledSeverityWrapper = styled.div<{ color: string; fontSize?: string }>`
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
  line-height: normal;
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
`;

export const StyledSeverityIcon = styled(SeverityIcon)<{ color: string }>`
  &.low {
    > rect:nth-of-type(4) {
      fill: ${({ color }) => color};
    }
  }

  &.medium {
    > rect:nth-of-type(4),
    > rect:nth-of-type(3) {
      fill: ${({ color }) => color};
    }
  }

  &.high {
    > rect:nth-of-type(4),
    > rect:nth-of-type(3),
    > rect:nth-of-type(2) {
      fill: ${({ color }) => color};
    }
  }

  &.extreme {
    > rect {
      fill: ${({ color }) => color};
    }
  }
`;
