import { isNull } from 'lodash';
import { Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FONT_SIZE } from '@/views/EntityMonitoringView/components/EntityDetails/components/contstants';
import { formatFloatToPercent } from '@/helpers/helpers';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import {
  StyledColumnGridWrapper,
  StyledLink,
  StyledRowGridWrapper,
} from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { ERoutes } from '@/types/routes';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';

export const DefaultRisks = ({
  isRated,
  displayName,
  pdYear,
  pdChange,
}: Pick<IEntityDetailsProps, 'isRated' | 'displayName' | 'pdYear' | 'pdChange'>) => {
  return (
    <>
      <Typography color="white.100" fontSize={FONT_SIZE}>
        Default Risks
      </Typography>
      <StyledColumnGridWrapper>
        {isRated ? (
          <RatedEntity displayName={displayName} pdYear={pdYear} pdChange={pdChange} />
        ) : (
          <Typography color="#ffffff80" fontSize={FONT_SIZE}>
            Not Rated
          </Typography>
        )}
      </StyledColumnGridWrapper>
    </>
  );
};

const RatedEntity = ({
  displayName,
  pdYear,
  pdChange,
}: Pick<IEntityDetailsProps, 'displayName' | 'pdYear' | 'pdChange'>) => {
  const { shouldDisplayRisks } = usePermissionsManager();
  const { getExchangeLabelByDisplayName } = useEntitiesList();

  const blurStyle = { filter: `${shouldDisplayRisks ? 'none' : 'blur(5px)'}` };

  const pdYearToDisplay = !isNull(pdYear) ? formatFloatToPercent(pdYear) : '';
  const pdChangeToDisplay = !isNull(pdChange) ? formatFloatToPercent(pdChange) : '';

  return (
    <>
      <StyledRowGridWrapper>
        <Typography color="#ffffff80" fontSize={FONT_SIZE} noWrap>
          1 Year PD
        </Typography>
        <Typography color="white.100" fontSize={FONT_SIZE} style={blurStyle} noWrap>
          {shouldDisplayRisks ? pdYearToDisplay : 'XX.XX%'}
        </Typography>
      </StyledRowGridWrapper>

      <StyledRowGridWrapper>
        <Typography color="#ffffff80" fontSize={FONT_SIZE} noWrap>
          1W PD Change
        </Typography>
        <Typography color="white.100" fontSize={FONT_SIZE} style={blurStyle} noWrap>
          {shouldDisplayRisks ? pdChangeToDisplay : 'XX.XX%'}
        </Typography>
      </StyledRowGridWrapper>

      {!!displayName && (
        <StyledRowGridWrapper>
          <StyledLink href={`${ERoutes.RATINGS}/${getExchangeLabelByDisplayName(displayName)}`} underline="none">
            <Typography color="#ffffff80" fontSize={FONT_SIZE} display="flex" alignItems="center" gap="3px" noWrap>
              Go To Details <ArrowForwardIcon fontSize="small" />
            </Typography>
          </StyledLink>
        </StyledRowGridWrapper>
      )}
    </>
  );
};
