import React from 'react';
import Sidebar from '@/layouts/MainLayout/components/SideBar/SideBar';
import RatingsHeader from '@/components/molecules/Headers/RatingsHeader';
import EntityRatingsHeader from '@/components/molecules/Headers/EntityRatingsHeader';
import RiskSimulatorHeader from '@/components/molecules/Headers/RiskSimulatorHeader';
import MonitoringHeader from '@/components/molecules/Headers/MonitoringHeader';
import MonitoringSettingsHeader from '@/components/molecules/Headers/MonitoringSettingsHeader';
import DocumentationHeader from '@/components/molecules/Headers/DocumentationHeader';
import { SettingsHeader } from '@/components/molecules/Headers/SettingsHeader';
import { StyledAppContainer, StyledHeaderContainer, StyledMain } from '@/layouts/MainLayout/MainLayout.styled';

interface LayoutProps {
  children?: React.ReactNode;
  header?:
    | null
    | 'ratings'
    | 'entityRatings'
    | 'riskSimulator'
    | 'monitoringDashboardType'
    | 'monitoringSettings'
    | 'entityMonitoring'
    | 'documentationChangelog'
    | 'settings';
}

const MainLayout: React.FC<LayoutProps> = ({ children, header }) => {
  let headerToDisplay = null;
  switch (header) {
    case 'ratings':
      headerToDisplay = <RatingsHeader />;
      break;
    case 'entityRatings':
      headerToDisplay = <EntityRatingsHeader />;
      break;
    case 'riskSimulator':
      headerToDisplay = <RiskSimulatorHeader />;
      break;
    case 'monitoringDashboardType':
      headerToDisplay = (
        <MonitoringHeader shouldDisplaySubtitle shouldDisplaySelectDashboardButton shouldDisplaySettingsButton />
      );
      break;
    case 'monitoringSettings':
      headerToDisplay = <MonitoringSettingsHeader />;
      break;
    case 'entityMonitoring':
      headerToDisplay = <MonitoringHeader shouldDisplayBackButton />;
      break;
    case 'documentationChangelog':
      headerToDisplay = <DocumentationHeader subtitle="Changelog" />;
      break;
    case 'settings':
      headerToDisplay = <SettingsHeader />;
      break;
    default:
      headerToDisplay = null;
      break;
  }

  const shouldDisableFeedbackSpace = header === 'ratings';
  const shouldRenderFromTop = !header;

  return (
    <StyledAppContainer className="default-layout">
      <Sidebar />
      {headerToDisplay && <StyledHeaderContainer>{headerToDisplay}</StyledHeaderContainer>}
      <StyledMain
        className="main"
        disableFeedbackSpace={shouldDisableFeedbackSpace}
        shouldRenderFromTop={shouldRenderFromTop}
      >
        {children}
      </StyledMain>
    </StyledAppContainer>
  );
};

export default MainLayout;
