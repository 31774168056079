import { useState } from 'react';
import { isEmpty } from 'lodash';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { ELocalStorage } from '@/types/storage';

const SHOW_ALL_VISIBILITY_COLUMN_MODEL = {
  displayName: true,
  riskZone: true,
  adjustedBalanceSeverity: true,
  onChainActivitySeverity: true,
  tag: true,
  latestBalance: true,
  chartData: true,
};

export const useColumnVisibilityModelManager = () => {
  const getInitialColumnVisibilityModel = () => {
    const adjustedBalanceSeverity = localStorage.getItem(ELocalStorage.MONITORING_TABLE_ADJUSTED_BALANCE_SEVERITY);
    const onChainActivitySeverity = localStorage.getItem(ELocalStorage.MONITORING_TABLE_ON_CHAIN_ACTIVITY_SEVERITY);

    return {
      displayName: true,
      riskZone: true,
      adjustedBalanceSeverity: !!adjustedBalanceSeverity,
      onChainActivitySeverity: !!onChainActivitySeverity,
      tag: true,
      latestBalance: true,
      chartData: true,
    };
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
    getInitialColumnVisibilityModel(),
  );

  const handleOnColumnVisibilityModelChange = (newModel: GridColumnVisibilityModel) => {
    const isEmptyModel = isEmpty(newModel);
    const isAdjustedBalanceSeverityInStorage = localStorage.getItem(
      ELocalStorage.MONITORING_TABLE_ADJUSTED_BALANCE_SEVERITY,
    );
    const isOnChainActivitySeverityInStorage = localStorage.getItem(
      ELocalStorage.MONITORING_TABLE_ON_CHAIN_ACTIVITY_SEVERITY,
    );

    if (isEmptyModel) {
      !isAdjustedBalanceSeverityInStorage &&
        localStorage.setItem(ELocalStorage.MONITORING_TABLE_ADJUSTED_BALANCE_SEVERITY, 'isVisible');

      !isOnChainActivitySeverityInStorage &&
        localStorage.setItem(ELocalStorage.MONITORING_TABLE_ON_CHAIN_ACTIVITY_SEVERITY, 'isVisible');

      return setColumnVisibilityModel(SHOW_ALL_VISIBILITY_COLUMN_MODEL);
    }

    const isAdjustedBalanceSeverity = newModel.adjustedBalanceSeverity;

    const isOnChainActivitySeverity = newModel.onChainActivitySeverity;

    if (!isAdjustedBalanceSeverityInStorage && isAdjustedBalanceSeverity) {
      localStorage.setItem(ELocalStorage.MONITORING_TABLE_ADJUSTED_BALANCE_SEVERITY, 'isVisible');
    }

    if (isAdjustedBalanceSeverityInStorage && !isAdjustedBalanceSeverity) {
      localStorage.removeItem(ELocalStorage.MONITORING_TABLE_ADJUSTED_BALANCE_SEVERITY);
    }

    if (!isOnChainActivitySeverityInStorage && isOnChainActivitySeverity) {
      localStorage.setItem(ELocalStorage.MONITORING_TABLE_ON_CHAIN_ACTIVITY_SEVERITY, 'isVisible');
    }

    if (isOnChainActivitySeverityInStorage && !isOnChainActivitySeverity) {
      localStorage.removeItem(ELocalStorage.MONITORING_TABLE_ON_CHAIN_ACTIVITY_SEVERITY);
    }

    setColumnVisibilityModel(newModel);
  };

  return {
    columnVisibilityModel,
    handleOnColumnVisibilityModelChange,
  };
};
