export enum ELocalStorage {
  ACCESS_TOKEN = 'token',
  USER = 'user',
  PORTFOLIO_CSV_DATA = 'portfolioCsvData',
  MONITORING_TABLE_ADJUSTED_BALANCE_SEVERITY = 'adjustedBalanceSeverityVisible',
  MONITORING_TABLE_ON_CHAIN_ACTIVITY_SEVERITY = 'onChainActivitySeverityVisible',
}

export enum ESessionStorage {
  REDIRECT_URL = 'redirectUrl',
}
