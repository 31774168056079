import { Typography } from '@mui/material';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { DefaultRisks } from '@/views/EntityMonitoringView/components/EntityDetails/components/DefaultRisks/DefaultRisks';
import { ShortTermRisk } from '@/views/EntityMonitoringView/components/EntityDetails/components/ShortTermRisk/ShortTermRisk';
import { SignificantData } from '@/views/EntityMonitoringView/components/EntityDetails/components/SignificantData/SignificantData';
import {
  StyledColumnFlexWrapper,
  StyledEntityWrapper,
  StyledGridSectionContainer,
} from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { TSeverity } from '@/types/severityLevel';
import { RiskZoneKey } from '@/types/riskZone';

export interface IEntityDetailsProps {
  riskZone: RiskZoneKey;
  displayName: string | null;
  isRated: boolean;
  pdChange: number | null;
  pdYear: number | null;
  balance: number | null;
  inflow: number | null;
  outflow: number | null;
  totalFlow: number | null;
  netflow: number | null;
  tag: string | null;
  onChainActivity: TSeverity;
  adjustedBalance: TSeverity;
  flowDynamics: string | null;
}

export const EntityDetails = ({
  displayName,
  isRated,
  balance,
  inflow,
  outflow,
  totalFlow,
  netflow,
  pdYear,
  pdChange,
  riskZone,
  tag,
  onChainActivity,
  adjustedBalance,
  flowDynamics,
}: IEntityDetailsProps) => {
  const { getExchangeLabelByDisplayName } = useEntitiesList();
  const entityName = displayName ? getExchangeLabelByDisplayName(displayName) : '';

  return (
    <>
      <StyledEntityWrapper>
        <ExchangeLogo companyName={entityName} />
        <Typography color="white.100" fontSize="24px">
          {displayName}
        </Typography>
      </StyledEntityWrapper>

      <StyledGridSectionContainer>
        <StyledColumnFlexWrapper>
          <ShortTermRisk
            riskZone={riskZone}
            tag={tag}
            onChainActivity={onChainActivity}
            adjustedBalance={adjustedBalance}
            flowDynamics={flowDynamics}
          />
        </StyledColumnFlexWrapper>

        <StyledColumnFlexWrapper>
          <SignificantData
            balance={balance}
            outflow={outflow}
            inflow={inflow}
            totalFlow={totalFlow}
            netflow={netflow}
          />
        </StyledColumnFlexWrapper>

        <StyledColumnFlexWrapper>
          <DefaultRisks isRated={isRated} displayName={displayName} pdYear={pdYear} pdChange={pdChange} />
        </StyledColumnFlexWrapper>
      </StyledGridSectionContainer>
    </>
  );
};
