import { TMonitoringDashboardOption } from '@/types/dashboardTypes';

export const monitoringDashboardOptions: TMonitoringDashboardOption[] = [
  { label: 'Brokers/OTC', value: 'fund' },
  { label: 'Custodians', value: 'custody' },
  { label: 'Exchanges', value: 'cex' },
  { label: 'Heightened Activity', value: 'peak' },
  { label: 'My Monitored Entities', value: 'custom' },
  { label: 'Stable Coin Issuers', value: 'stablecoin_issuer' },
];

export const MONITORING_DASHBOARD_TYPES = ['cex', 'custody', 'fund', 'stablecoin_issuer', 'custom', 'peak'] as const;
