import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography } from '@mui/material';
import styled from '@emotion/styled';
import InfoIcon from '@/assets/icons/info.svg';
import ArrowLeft from '@/assets/icons/arrow-left.svg?react';
import { HEADER_HEIGHT } from '@/layouts/MainLayout/constants';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import Button from '@/components/atoms/Button/Button';

const StyledHeader = styled(AppBar)`
  height: ${HEADER_HEIGHT};
  width: 100%;
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

const MonitoringSettingsHeader = () => {
  const { shouldDisplayAlerts } = usePermissionsManager();
  const navigate = useNavigate();

  if (!shouldDisplayAlerts) {
    return (
      <StyledHeader position="static">
        <Toolbar>
          <Typography variant="h4">On-Chain Monitoring Preferences</Typography>
        </Toolbar>
      </StyledHeader>
    );
  }

  return (
    <StyledHeader position="static">
      <Toolbar sx={{ justifyContent: 'space-between', overflow: 'auto', height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <Button
            onClick={() => navigate(-1)}
            skipTypography
            fitContentWidth
            disableRipple
            blockHoverBehavior
            sx={{ paddingInline: '5px' }}
          >
            <ArrowLeft />
          </Button>

          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="h4" style={{ flexGrow: 1, marginRight: '10px' }} noWrap>
              On-Chain Monitoring Preferences
            </Typography>
            <Tooltip arrow title="On-Chain Monitoring Preferences - set up your own custom dashbord">
              <img src={InfoIcon} alt="Info icon" />
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    </StyledHeader>
  );
};

export default MonitoringSettingsHeader;
