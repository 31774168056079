import { useEffect, useRef } from 'react';
import { EntityDetails } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';
import { AnomaliesTable } from '@/views/EntityMonitoringView/components/AnomaliesTable/AnomaliesTable';
import { ChainDataCharts } from '@/views/EntityMonitoringView/components/ChainDataCharts/ChainDataCharts';
import { MonitoredStatusChart } from '@/views/EntityMonitoringView/components/MonitoredStatusChart/MonitoredStatusChart';
import {
  StyledContainer,
  StyledTextWrapper,
  StyledWrapper,
} from '@/views/EntityMonitoringView/EntityMonitoringView.styled';
import { IGetMonitoringDetailsResponse } from '@/api/monitoring/monitoring.types';

interface IEntityMonitoringViewProps {
  fetchedData: IGetMonitoringDetailsResponse;
}

export const EntityMonitoringView = ({ fetchedData }: IEntityMonitoringViewProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const {
    displayName,
    analystNotes,
    isRated,
    balance,
    inflow,
    outflow,
    totalFlow,
    netflow,
    pdYear,
    pdChange,
    severity,
    flowDynamics,
    latestAnomalies,
    agioId,
    riskZones,
    balances,
    netflowData,
    totalFlowData,
    hourlyNetflowData,
    hourlyTotalFlowData,
    hourlyOutflowTotalflowRatio,
  } = fetchedData;

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'auto', inline: 'center' });
  }, []);

  return (
    <StyledContainer ref={scrollRef}>
      <StyledWrapper>
        <EntityDetails
          displayName={displayName}
          isRated={!!isRated}
          balance={balance}
          inflow={inflow}
          outflow={outflow}
          totalFlow={totalFlow}
          netflow={netflow}
          pdYear={pdYear}
          pdChange={pdChange}
          riskZone={riskZones[0]?.value}
          tag={severity.description}
          onChainActivity={severity.totalFlow}
          adjustedBalance={severity.netflow}
          flowDynamics={flowDynamics}
        />

        {!!analystNotes && <StyledTextWrapper dangerouslySetInnerHTML={{ __html: analystNotes }} />}

        <MonitoredStatusChart riskZones={riskZones} />

        <AnomaliesTable latestAnomalies={latestAnomalies} />

        <ChainDataCharts
          agioId={agioId}
          balances={balances}
          netflowData={netflowData}
          totalFlowData={totalFlowData}
          hourlyNetflowData={hourlyNetflowData}
          hourlyTotalFlowData={hourlyTotalFlowData}
          hourlyOutflowTotalflowRatio={hourlyOutflowTotalflowRatio}
        />
      </StyledWrapper>
    </StyledContainer>
  );
};
