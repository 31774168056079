import { adjustRiskZoneColorFromValue } from '@/helpers/riskZoneMethods';
import { riskZones } from '@/constants/InitialState/riskZone';
import { StyledRiskZoneIcon, StyledRiskZoneWrapper } from './RiskZoneBarsIcon.styled';
import { TRiskZone } from '@/types/riskZone';

interface IRiskZoneBarsIconProps {
  riskZone: TRiskZone;
  fontSize?: string;
}

export const RiskZoneBarsIcon = ({ riskZone, fontSize }: IRiskZoneBarsIconProps) => {
  if (riskZone === null || !riskZone) {
    riskZone = 'no_data';
  }

  const label = riskZones.find(({ value }) => value === riskZone);

  if (!label) return null;

  const riskZoneColor = adjustRiskZoneColorFromValue(riskZone);
  const isNoRisk = riskZone === 'no_risk' || riskZone === 'no_data';
  const isElevatedRisk = riskZone === 'critical';

  return (
    <StyledRiskZoneWrapper color={riskZoneColor} fontSize={fontSize} shouldBeBold={isElevatedRisk}>
      {label.name}
      {!isNoRisk && <StyledRiskZoneIcon color={riskZoneColor} className={riskZone} />}
    </StyledRiskZoneWrapper>
  );
};
