import { RiskZoneKey, TRiskZone } from '@/types/riskZone';

export const adjustRiskZoneToNumber = (riskZone: TRiskZone): number | null => {
  switch (riskZone) {
    case 'critical':
      return 3;
    case 'high':
      return 2;
    case 'moderate':
      return 1;
    case 'no_risk':
      return 0;
    default:
      return null;
  }
};

export const adjustRiskZoneColorFromNumber = (riskZone: number): string => {
  switch (riskZone) {
    case 3:
      return '#C00000';
    case 2:
      return '#F60102';
    case 1:
      return '#FAC002';
    default:
      return '#4EA72E';
  }
};

export const adjustRiskZoneColorFromValue = (riskZone: RiskZoneKey): string => {
  switch (riskZone) {
    case 'critical':
      return '#C00000';
    case 'high':
      return '#F60102';
    case 'moderate':
      return '#FAC002';
    case 'no_risk':
      return '#4EA72E';
    default:
      return '#808080';
  }
};

export const adjustRiskZoneToNumberForAlertStatusTrending = (riskZone: TRiskZone): number | null => {
  switch (riskZone) {
    case 'critical':
      return 4;
    case 'high':
      return 3;
    case 'moderate':
      return 2;
    case 'no_risk':
      return 1;
    default:
      return null;
  }
};

export const adjustRiskZoneChartLabelForAlertStatusTrending = (riskZone: number | undefined): string => {
  switch (riskZone) {
    case 4:
      return 'Elevated';
    case 3:
      return 'Heightened';
    case 2:
      return 'Monitored';
    case 1:
      return 'Baseline';
    default:
      return '';
  }
};
