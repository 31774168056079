import { ChartData, ChartPoint } from '@/types/chartPoint';
import { adjustRiskZoneToNumber, adjustRiskZoneToNumberForAlertStatusTrending } from '@/helpers/riskZoneMethods';
import { adjustSeverityToNumberForAlertStatusTrending } from '@/helpers/severityLevelMethods';
import { IRiskZoneDTO } from '@/types/riskZone';

interface DataItem {
  timestamp: string;
  value: number | null;
}

export const mapChainData = (data: Array<DataItem>): ChartData => {
  let mapped: ChartData = [];
  mapped = data
    .filter((item) => item?.value)
    .map((item) => {
      return {
        x: new Date(item.timestamp).getTime(),
        y: item.value,
      };
    });

  return mapped;
};

export const mapRiskZoneChartData = (data: Array<IRiskZoneDTO>): ChartData => {
  let mapped: ChartPoint[] = [];
  mapped = data
    .filter((item) => item?.value)
    .map((item) => {
      return {
        x: new Date(item.timestamp).getTime(),
        y: adjustRiskZoneToNumber(item.value),
      };
    });

  return mapped;
};

export const mapAlertStatusTrendingChartData = (data: Array<IRiskZoneDTO>): ChartData => {
  let mapped: ChartPoint[] = [];
  mapped = data
    .filter((item) => item?.value)
    .map((item) => {
      return {
        x: new Date(item.timestamp).getTime(),
        y: adjustRiskZoneToNumberForAlertStatusTrending(item.value),
      };
    });

  return mapped;
};

export const mapSeverityChartDataFromRiskZoneDataset = (
  data: Array<IRiskZoneDTO>,
  key: 'netflow' | 'totalFlow',
): ChartData => {
  let mapped: ChartPoint[] = [];
  mapped = data
    .filter((item) => item?.value)
    .map((item) => {
      return {
        x: new Date(item.timestamp).getTime(),
        y: adjustSeverityToNumberForAlertStatusTrending(item[key]),
      };
    });

  return mapped;
};
